import React, { useEffect } from "react"
import { navigate } from "gatsby-link"
import Box from '@mui/material/Box'
import { grey, red } from '@mui/material/colors'


import { Typography } from "@mui/material"


const Cancel = () => {

 

  useEffect(()=>{

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'purchaseError');
      }
    }


    setTimeout(() => {
         navigate('/')
    }, 10000);

  },[])

  return (<Box width="100%" height="100%" marginTop="40vh" display="flex" flexDirection="row" justifyContent="center" justifyItems="center" alignContent="center" alignItems="center">
  <Box borderRadius="20px" width="100%" maxWidth="450px" bgcolor={red[700]} padding="30px">
      <Typography style={{color: 'white'}}>
        Une erreur est survenue lors de votre inscription à un événement sur Pekoia. Veuillez vous re-inscrire ou nous contacter sur contact@pekoia.com

      </Typography>
      
      </Box>

  </Box>)
}





export default Cancel